import React from 'react';
import { ToastContent } from 'react-toastify';
import styled from 'styled-components';

import { CustomMessageOptions, FlashType } from '../../../FlashNotificationContext';

export type ToastNotificationWithStructuredContentProps = {
  message: ToastContent;
  kind: FlashType;
} & CustomMessageOptions;

// We can't reuse theme variables here because the ReactOnRailsToast component is used for Saas Admin, and is not part of the ThemeProvider
const ReactToastContentSubtitle = styled.p`
  font-weight: 400;
  color: rgb(42, 42, 42);
  margin-bottom: 0;
  margin-top: 0;
  font-size: 14.224px;
  line-height: 18px;
`;

const ReactToastContentTitle = styled(ReactToastContentSubtitle)`
  font-weight: 700;
`;

const ReactToastContentWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ReactToastContentMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 12px;
  font-family: Poppins, Arial, sans-serif;
`;

const ReactToastContentEmojiWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  font-size: 41px;
`;

const ToastNotificationWithStructuredContent = ({
  kind,
  message,
  hasCustomMessageStructure,
  customTitle,
}: ToastNotificationWithStructuredContentProps) => {
  function getNotificationDataByKind() {
    switch (kind) {
      case 'success':
        return {
          title: 'Success',
          emoji: '👍',
        };
      case 'warning':
        return {
          title: 'Warning',
          emoji: '⚠️',
        };
      case 'error':
        return {
          title: 'Error',
          emoji: '😥',
        };
      default:
        return {
          title: 'Info',
          emoji: '👉',
        };
    }
  }
  const notificationData = getNotificationDataByKind();
  const title = customTitle ? customTitle : notificationData.title;

  const getTitle = () => {
    const isTitleString = typeof title === 'string';

    return isTitleString ? <span dangerouslySetInnerHTML={{ __html: title }} /> : title;
  };

  return (
    <ReactToastContentWrapper>
      <ReactToastContentEmojiWrapper>{notificationData.emoji}</ReactToastContentEmojiWrapper>
      <ReactToastContentMessageWrapper>
        {hasCustomMessageStructure ? (
          message
        ) : (
          <>
            <ReactToastContentTitle>{getTitle()}</ReactToastContentTitle>
            <ReactToastContentSubtitle dangerouslySetInnerHTML={{ __html: message as string }} />
          </>
        )}
      </ReactToastContentMessageWrapper>
    </ReactToastContentWrapper>
  );
};

export default ToastNotificationWithStructuredContent;
